import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
    Box,
    Collapse,
    Flex,
    IconButton,
    Image,
    Stack,
    Text,
    useBreakpointValue,
    useColorModeValue,
    useDisclosure,
} from "@chakra-ui/react";
import Crumbs from "components/Breadcrumb";

import { themeMockNavbar } from "mock";
import { DesktopNav } from "./DesktopNav";
import { MobileNav } from "./MobileNav";

export default function Navbar() {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <>
            <Box mb={8}>
                <Flex
                    bg="primary.500"
                    color={"white"}
                    minH={"60px"}
                    py={{ base: 2 }}
                    px={{ base: 4 }}
                    align={"center"}
                    flexDirection={"row-reverse"}
                >
                    <Flex
                        borderWidth="3px"
                        borderStyle="solid"
                        borderColor="white"
                        borderRadius="xl"
                        ml={{ base: -2 }}
                        display={{ base: "end", md: "none" }}
                    >
                        <IconButton
                            onClick={onToggle}
                            icon={
                                isOpen ? (
                                    <CloseIcon w={3} h={3} />
                                ) : (
                                    <HamburgerIcon w={5} h={5} />
                                )
                            }
                            variant={"ghost"}
                            aria-label={"Toggle Navigation"}
                        />
                    </Flex>
                    <Flex
                        flex={{ base: 1 }}
                        justify={{ base: "center", md: "end" }}
                    >
                        <Text
                            textAlign={useBreakpointValue({
                                base: "center",
                                md: "left",
                            })}
                            fontFamily={"heading"}
                            color={useColorModeValue("gray.800", "white")}
                        ></Text>

                        <Flex
                            display={{ base: "none", md: "flex" }}
                            m={2}
                            ml={10}
                        >
                            <DesktopNav />
                        </Flex>
                    </Flex>
                    <Stack direction={"row"} spacing={6}>
                        <Image
                            src={themeMockNavbar.logo}
                            alt={themeMockNavbar.alt}
                            borderRadius={2}
                        />
                    </Stack>
                </Flex>
                <Flex display={{ base: "none", md: "flex" }} ml={10}>
                    <Box p={4}>
                        <Crumbs />
                    </Box>
                </Flex>
                <Collapse in={isOpen} animateOpacity>
                    <MobileNav />
                </Collapse>
            </Box>
        </>
    );
}
