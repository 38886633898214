import text from "constants/applicationText";
import { BsFileEarmark, BsFileEarmarkText } from "react-icons/bs";
export interface INavbar {
    label: string;
    icon?: React.ComponentElement<INavbar, any>;
    children?: Array<INavbar>;
    href?: string;
}

export const NavItems: INavbar[] = [
    {
        label: text.components.navbarItems.consents,
        children: [
            {
                label: text.components.navbarItems.newConsent,
                href: "/services/consent/transmitter/home",
                icon: <BsFileEarmark />,
            },
            {
                label: text.components.navbarItems.myConsents,
                href: "/services/management/listconsent",
                icon: <BsFileEarmarkText />,
            },
        ],
    },
    {
        label: text.components.navbarItems.youDoubt,
        href: "https://opinbrasil.com.br/cidadao/saiba-mais/perguntas-frequentes",
        icon: <BsFileEarmarkText />,
    },
];
