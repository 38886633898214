import { ChakraProvider } from "@chakra-ui/react";

import Navbar from "components/Navbar";
import type { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import { theme } from "../styles/theme";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
function MyApp({ Component, pageProps }: AppProps) {
    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                <Navbar />
                <Component {...pageProps} />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </ChakraProvider>
        </QueryClientProvider>
    );
}
export default MyApp;
