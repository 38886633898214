import { Stack } from "@chakra-ui/react";
import { MobileNavItem } from "../MobileNavItem";
import { INavbar, NavItems } from "components/Navbar/NavItems";

export const MobileNav = () => {
    return (
        <Stack bg={"white"} p={4} display={{ md: "none" }}>
            {NavItems.map((navItem: INavbar) => (
                <MobileNavItem key={navItem.label} {...navItem} />
            ))}
        </Stack>
    );
};
