import { ArrowDownIcon } from "@chakra-ui/icons";
import {
    Box,
    Popover,
    PopoverTrigger,
    Stack,
    Link,
    PopoverContent,
} from "@chakra-ui/react";
import { DesktopSubNav } from "components/Navbar/DesktopSubNav";

import { INavbar, NavItems } from "components/Navbar/NavItems";

export const DesktopNav = () => {
    const popoverContentBgColor = "white";
    return (
        <Stack direction={"row"} spacing={8}>
            {NavItems.map((navItem: INavbar) => (
                <Box key={navItem.label}>
                    <Popover trigger={"hover"} placement={"bottom-end"}>
                        <PopoverTrigger>
                            <Link
                                p={2}
                                href={navItem.href ?? "#"}
                                fontSize={"sm"}
                                fontWeight={700}
                                color={"white"}
                                _hover={{
                                    textDecoration: "underline",
                                    textUnderlineOffset: "0.5rem",
                                    opacity: 0.5,
                                }}
                            >
                                {navItem.label}
                            </Link>
                        </PopoverTrigger>
                        <ArrowDownIcon color="white" />
                        {navItem.children && (
                            <>
                                <PopoverContent
                                    border={1}
                                    boxShadow={"xl"}
                                    bg={popoverContentBgColor}
                                    p={4}
                                    rounded={"xl"}
                                    minW={"sm"}
                                >
                                    <Stack>
                                        {navItem.children.map(
                                            (child: INavbar) => (
                                                <DesktopSubNav
                                                    key={child.label}
                                                    {...child}
                                                />
                                            )
                                        )}
                                    </Stack>
                                </PopoverContent>
                            </>
                        )}
                    </Popover>
                </Box>
            ))}
        </Stack>
    );
};
