import { colors } from "./themeColor";
import { image } from "./themeImage";

const themeMockColors = {
    primary: colors.primary,
    status: colors.status,
};
const themeMockNavbar = {
    logo: image.navbar.logo,
    alt: image.navbar.alt,
};

const themeMockImage = {
    image: image.menu.image,
    alt: image.menu.alt,
};
const themeMockBank = {
    image: image.menu.image,
    alt: image.menu.alt,
};

export { themeMockColors, themeMockNavbar, themeMockImage, themeMockBank };
