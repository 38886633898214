import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
    root: { width: "100%" },
    button: { bg: "gray.100" },
    container: {
        borderRadius: "lg",
        marginBottom: 6,
    },
    panel: {
        borderRadius: "md",
        border: "1px solid gray.100",
    },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
