export const image = {
    navbar: {
        logo: "/assets/images/BrasilCap.svg",
        alt: "Logo BrasilCap",
    },
    menu: {
        image: "/assets/images/openInsurance.svg",
        alt: "Open Insurance",
    },
    dev: {
        image: "/assets/images/dev.svg",
        alt: "Open Insurance",
    },
};
