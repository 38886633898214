import { ArrowDownIcon } from "@chakra-ui/icons";
import {
    Collapse,
    Flex,
    Icon,
    Link,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { INavbar } from "../NavItems";

export const MobileNavItem = ({ label, children, href }: INavbar) => {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <Stack spacing={4} onClick={children && onToggle}>
            <Flex
                py={2}
                as={Link}
                href={href ?? "#"}
                justify={"space-between"}
                align={"center"}
                _hover={{
                    textDecoration: "none",
                }}
            >
                <Text fontWeight={700} color={"black"}>
                    {label}
                </Text>
                {children && (
                    <Icon
                        as={ArrowDownIcon}
                        transition={"all .25s ease-in-out"}
                        transform={isOpen ? "rotate(180deg)" : ""}
                        w={6}
                        h={6}
                    />
                )}
            </Flex>

            <Collapse
                in={isOpen}
                animateOpacity
                style={{ marginTop: "0!important" }}
            >
                <Stack
                    mt={2}
                    pl={4}
                    borderLeft={1}
                    borderStyle={"solid"}
                    borderColor={"gray"}
                    fontWeight={700}
                    color={"black"}
                    align={"start"}
                >
                    {children &&
                        children.map((child, index) => (
                            <Flex key={index}>
                                <Stack direction={"row"} align={"center"}>
                                    {child.icon}
                                    <Link
                                        key={child.label}
                                        py={2}
                                        href={child.href}
                                    >
                                        {child.label}
                                    </Link>
                                </Stack>
                            </Flex>
                        ))}
                </Stack>
            </Collapse>
        </Stack>
    );
};
