export const colors = {
    primary: {
        30: "#4CB2FF",
        50: "#CCE9FF",
        100: "#99D3FF",
        200: "#66BDFF",
        300: "#4CB2FF",
        400: "#33A7FF",
        500: "#005799",
        600: "#004880",
        700: "#003A66",
        800: "#002B4D",
        900: "#001D33",
    },
    gray: {
        50: "#E6E6E6",
        100: "#D9D9D9",
        200: "#CCCCCC",
        300: "#BFBFBF",
        400: "#B3B3B3",
        500: "#999999",
        600: "#808080",
        700: "#666666",
        800: "#4D4D4D",
        900: "#333333",
    },
    warning: {
        warning20: "#BEA45F",
    },
    status: {
        warning: {
            warning50: "#F1C40F",
        },
    },
};
