import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Flex,
    Spacer,
} from "@chakra-ui/react";
import { BsHouse } from "react-icons/bs";
import { BREADCRUMB_ITEMS } from "./items";
import text from "constants/applicationText";
type PathUrl = {
    href: string;
    label: string | string[];
};

function Crumbs() {
    const router = useRouter();
    const [breadcrumbs, setBreadcrumbs] = useState<PathUrl[]>([]);

    useEffect(() => {
        const pathWithoutQuery = router.asPath.split("?")[0];
        let pathArray = pathWithoutQuery.split("/");
        pathArray.shift();

        pathArray = pathArray.filter((path) => path !== "");

        const breadcrumbs = pathArray.map((path, index) => {
            const href =
                "/" +
                pathArray
                    .slice(0, index + 1)
                    .join("/")
                    .toString();

            return {
                href,
                label: BREADCRUMB_ITEMS.map((item) =>
                    item.to == href ? item.label : ""
                ),
            };
        });

        setBreadcrumbs(breadcrumbs);
    }, [router.asPath]);
    return (
        <>
            <Breadcrumb
                spacing="0.2rem"
                separator={<ChevronRightIcon color="gray.900" />}
            >
                <BreadcrumbItem>
                    <BreadcrumbLink
                        color="gray.900"
                        fontWeight="bold"
                        fontSize="sm"
                        href="/"
                    >
                        <Flex>
                            <BsHouse size={16} />
                            <Spacer m="1" />
                            {text.components.breadcrumbs.home}
                        </Flex>
                    </BreadcrumbLink>
                </BreadcrumbItem>
                {breadcrumbs.length > 1 && (
                    <BreadcrumbItem>
                        <BreadcrumbLink
                            fontWeight="bold"
                            fontSize="sm"
                            onClick={() => router.back()}
                        >
                            ...
                        </BreadcrumbLink>
                    </BreadcrumbItem>
                )}
                {breadcrumbs.length > 1 && (
                    <>
                        <BreadcrumbItem>
                            <BreadcrumbLink
                                color="primary.400"
                                fontWeight="bold"
                                fontSize="sm"
                                href={breadcrumbs.at(-1)?.href}
                            >
                                {breadcrumbs.at(-1)?.label}
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                    </>
                )}
            </Breadcrumb>
        </>
    );
}

export default Crumbs;
