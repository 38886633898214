import text from "constants/applicationText";

type IBreadcrump = {
    label: string;
    to: string;
};
export const BREADCRUMB_ITEMS: Array<IBreadcrump> = [
    {
        to: "/accounts/consent/transmitter/home",
        label: text.components.breadcrumbs.generateConsents,
    },
    {
        to: "/services/consent/receiver/listconsent",
        label: text.components.breadcrumbs.consents,
    },
    {
        to: "/services/consent/transmitter/dataconfirmation",
        label: "Confirmação de dados",
    },
    {
        to: "/services/management/shareconsent",
        label: text.components.breadcrumbs.consentDetail,
    },
    {
        to: "/services/consent/transmitter/capitalizationtitles",
        label: text.components.breadcrumbs.consentDetail,
    },
    {
        to: "/services/consent/transmitter/home",
        label: text.components.breadcrumbs.createConsent,
    },
    {
        to: "/services/consent/transmitter/redirection",
        label: text.components.breadcrumbs.redirect,
    },
    {
        to: "/services/management/listconsent",
        label: text.components.breadcrumbs.consents,
    },
    {
        to: "/services/consent/receiver/datasharingusers",
        label: text.components.breadcrumbs.dataSharing,
    },
];
