import { Flex, Link, Stack, Box, Text } from "@chakra-ui/react";
import { INavbar } from "components/Navbar/NavItems";

export const DesktopSubNav = ({ label, href, icon }: INavbar) => {
    return (
        <Link
            href={href}
            role={"group"}
            display={"block"}
            p={2}
            rounded={"md"}
            _hover={{ bg: "gray.100" }}
        >
            <Flex>
                <Stack direction={"row"} align={"center"} color={"black"}>
                    {icon}
                    <Box>
                        <Text transition={"all .3s ease"} fontWeight={700}>
                            {label}
                        </Text>
                    </Box>
                </Stack>
            </Flex>
        </Link>
    );
};
