import { extendTheme } from "@chakra-ui/react";
import { colors } from "mock/themeColor";
import { accordionTheme } from "./AccordionTheme";

export const theme = extendTheme({
    fonts: {
        heading: "Open Sans, sans-serif",
        body: "Open Sans, sans-serif",
    },
    styles: {
        global: {
            body: {
                bg: "white",
            },
        },
    },
    fontSize: {
        sm: "14px",
        md: "16px",
        lg: "18px",
        xl: "20px",
        "2xl": "24px",
        "3xl": "32px",
        "4xl": "40px",
        "5xl": "48px",
        "6xl": "56px",
        "7xl": "64px",
    },
    fontWeight: {
        regular: 400,
        bold: 700,
    },
    colors: {
        white: "white",
        black: "black",
        primary: {
            30: colors.primary[30],
            50: colors.primary[50],
            100: colors.primary[100],
            200: colors.primary[200],
            300: colors.primary[300],
            400: colors.primary[400],
            500: colors.primary[500],
            600: colors.primary[600],
            700: colors.primary[700],
            800: colors.primary[800],
            900: colors.primary[900],
        },
        warning: {
            20: colors.warning.warning20,
        },
        status: {
            warning: {
                50: colors.status.warning.warning50,
            },
        },
        consent: {
            authorized: "#07C50D",
            awaitingAuthorization: "#F1C40F",
            expired: "#E74C3C",
            rejected: "#3F3F3F",
        },
    },
    components: { Accordion: accordionTheme },
});
