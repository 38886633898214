export default {
    pages: {
        listConsents: {
            title: "Meus Consentimentos ",
            description: "Selecione o tipo de consetimento",
            select: "Selecione o tipo de consentimento",
        },
        shareConsents: {
            toast_success: "Cancelamento realizado com sucesso!",
            toast_error:
                "Não foi possível realizar o cancelamento do seu consentimento!",
            title: "Detalhes do Compartilhamento",
            box_sharing: " Compartilhamento ",
            box_client_Identification: "Indentificação do cliente",
            name: "Nome",
            creation_date: "Data de criação",
            authorization_date: "Data de autorização",
            Authorized_by: "Autorizado por",
            destination_institution: "Instituição de destino",
            sharing_term: "Prazo do Compartilhamento",
            sharing_reject: "Data ",
            Consent_ID: "ID do consentimento",
            destination: "Icatu",
        },
        redirection: {
            infoShare:
                "Vamos direcioná-lo para a página inicial, aguarde por alguns segundos para validação dos seus dados",
            shareDate: "Compartilhando Dados",
            redirecting: "Redirecionando",
            secureShare:
                "Seus dados estão sendo compartilhados de forma segura.",
            doNotStayInThePage:
                "Por favor deixe está aba aberta, para não ocorrer erros na transação de dados.",
            completedShare: "Compartilhamento completo",
            weSharing:
                "Estamos compartilhando os dados através do Open Finance, dessa forma você tem segurança total dos dados compartilhados",
        },
        home: {
            title: "Realize a criação do consentimento",
            selectPeriod: "Selecione um período",
            generateConsent: "Gerar consentimento",
        },
        dataConfirmation: {
            confirmConsent: "Consentimento confirmado!",
            doFail: "Cancelamento realizado com suacesso!",
            dataNotCreated:
                "O seu CPF/CNPJ não condiz com o consentimento criado, por favor, retorne realize login novamente!",
            loginAgain: "Por favor, realize o Login novamente",
            notFound: "Não encontrado",
            consentAlreadyRealized: "Você já realizou o consentimento!",
            error: "Ocorreu um erro!",
            confirmData: "Confirme os dados que serão compartilhados",
            redirectToInstitution:
                "Após a confirmação, você será redirecionado para a instituição selecionada.",
            cancelTitle: "Cancelamento de Compartilhamento",
            cancelDescription:
                "Ao confirmar, você estará cancelando compartilhamento de dados e será redirecionadopara a instituição de origem.",
        },
        capitalizationTitles: {
            title: "Titulos de capitalização",
            subTitle: "Selecione os títulos que você gostaria de compartilhar.",
            notAlterData: "Dados não alterados!",
            notAlterDataDescription:
                "Os dados não foram alterados, podemos dar andamento ao compartilhamento?",
            alterData: "Dados alterados!",
            alterDataDescription:
                "Os dados foram alterados, podemos dar andamento ao compartilhamento, com os dados que você selecionou?",
        },
        dataSharingUsers: {
            title: "Compartilhamento de dados",
            selectIntitutions: "Selecione uma instituição",
            filterTitle: "Tipos de instituição",
            filterOptions: [
                {
                    title: "Bancos",
                    value: "Ozone Financial Technology Limited",
                },
                { title: "Seguradoras", value: "seguros" },
                { title: "Outros", value: "-" },
            ],
            viewMoreDetailsInstitutions: "Ver mais Instituições",
            citizenOpenInsurance: "Portal Open Insurance",
        },

        index: {
            portal: "Portal do cidadão",
            terms: "Termos de uso",
            whatIsOpenInsurance: "O que é Open Insurance?",
            selectService: "Selecione o serviço desejado",
            newConsent: "Novo Consentimento",
            myConsents: "Meus Consentimentos",
        },
    },
    images: {
        imageOpenInsurance: "/assets/images/openInsurance.svg",
        name: "logo BrasilCap",
        url: "/assets/images/logo.svg",
        secondaryName: "logo icatu",
        secondaryUrl:
            "https://pbs.twimg.com/profile_images/1386524296466608128/VgXZoQTJ_400x400.jpg",
    },
    general: {
        physicalPerson: {
            name: "Nome",
            documents: "Documentos",
            maritalStatus: "Estado Civil",
            nationality: "Nacionalidade",
            company: "Empresa",
            sex: "Sexo",
            birthdayDate: "Data de Nascimento",
            identifier: "CPF",
        },
        legalEntity: {
            name: "Nome da Empresa",
            identifier: "CNPJ",
            email: "E-mail",
            partners: "Sócios",
        },
        months: "meses",
        cancel: "Cancelar",
        confirm: "Confirmar",
        back: "Voltar",
        identifier: "CPF",
        brazilian: "BRASILEIRA",
        address: "Endereço",
        postalCode: "Caixa Postal",
        stateCode: "UF",
        phone: "Telefone",
        title: "Título",
        serie: "Série",
        twoIdentifier: "CPF/CNPJ",
        filter: "Filtro",
        message: "Não encontramos os seus dados em nossa base!",
    },
    components: {
        accordion: {
            viewDetails: "Ver Detalhes",
            viewMoreDetails: "Ver mais detalhes",
            hello: "olá",
            mandatoryDataTitle: "Dados Obrigatórios",
            mandatoryDataDescription:
                "São dados necessários para o compartilhamento",
            registerDataTitle: "Dados Cadastrais",
            registerDataDescription:
                "Função da pessoa, Tipo da pessoa, Nome completo, CPF ou CNPJ",
            additionalInformationTitle: "Informações complementares",
            additionalInformationDescription:
                "Logadouro, Código Postal, UF, Celular",
            optionalDataTitle: "Dados Opcionais",
            optionalDataDescription: "São dados que podem ser compartilhados",
            notHaveOptional: "Não temos Dados Opcionais no momento para você!",
            capitalizationTitle: "Títulos de capitalização",
            yourCapitalizationDown: "Abaixo está os titulos que você possui.",
        },
        breadcrumbs: {
            generateConsents: "Gerador de consentimentos",
            consents: "Consentimentos",
            dataConfirm: "Confirmação de dados",
            consentDetail: "Detalhes do Consentimentos",
            createConsent: "Criação do Consentimento",
            redirect: "Redirecionamento",
            home: "Ínicio",
            dataSharing: "Compartilhamento de dados",
        },
        dataSharingUser: {
            clientIdentifier: "Identificação do Cliente",
            objective: "Objetivo do uso dos dados",
            shareData: "Compartilhamento de dados",
            shareDataWith: "Compartilhamento feito com:",
            tpp: "Icatú",
        },
        unexpectedError: {
            title: "Erro Inesperado",
            code: "Código do erro",
        },
        termContract: {
            deadlineShareData: "Prazo de compartilhamento",
            expirationDate: "Data de expiração do compartilhamento",
            text: "Caso deseje alterar o prazo ou os dados que serão compartilhados, volte a instituição selecionada e refaça os passos do compartilhamento.",
        },
        modalCancel: {
            cancelPermission: "Cancelar Permissão",
            description:
                "Ao cancelar o compartilhamentos dedados, os benefícios e funcionalidadesvinculados a essa permissão serãoperdidos.",
            confirmDescription:
                "Você tem certeza que deseja cancelar a permissão?",
            backToConsents: "Voltar para meus consentimento",
        },
        showModal: {
            title: "COMPARTILHAMENTO ENCERRADO",
            description:
                "O cancelamento do compartilhamento de dados foi finalizado com sucesso!",
            boldDescription:
                "Caso você queira ver o recibo de cancelamento, basta toque no botão",
            confirmButtonText: "Ver recibo do cancelamento",
        },
        shareDatailsUser: {
            institutionTitle: "Instituição",
            institutionCNPJ: "CNPJ",
            institutionCPF: "CPF",
        },

        modalItems: {
            fullList: "Lista completa de Dados Cadastrais",
            benefits:
                "Compartilhando esses dados estaremos oferencendo melhores beneficios de acordo com suas necessidades.",
            viewMoreAbout: "Ver mais sobre o",
            citizenPortal: "Portal do Cidadão",
        },
        navbarItems: {
            consents: "Consentimentos",
            newConsent: "Novo Consentimento",
            myConsents: "Meus Consentimentos",
            youDoubt: "Dúvidas",
        },
    },
};
